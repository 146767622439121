<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="!gettingSignature" class="po-header">
    <div class="po-header-toolbar-container">
        <div class="po-header-toolbar" [hidden]="editedItemCopy != null">
            <p-button *ngIf="LocalCacheManager.transactionQueue.length > 0" severity="danger" (click)="refresh()"><i class="fa fa-sync" style="margin-left: 5px; margin-right: 5px;"></i></p-button>
            <p-button *ngIf="authManager.currentUser.createPurchaseOrder || poManager.current.isLocked" (click)="printMenu.toggle($event)" icon="fa fa-print"></p-button>
            <p-button *ngIf="authManager.currentUser.deletePurchaseOrder" severity="danger" (click)="deletePO()" icon="pi pi-trash"></p-button>
            <p-button *ngIf="poManager.current.isLocked && authManager.currentUser.unlockPurchaseOrder" (click)="unlock()">Unlock</p-button>
            <p-button *ngIf="!poManager.current.isLocked && authManager.currentUser.createPurchaseOrder" (click)="addParts()">Add Parts</p-button>
            <p-button *ngIf="!poManager.current.isLocked && authManager.currentUser.createPurchaseOrder" (click)="updatePrices()">Update Prices</p-button>
            <p-button severity="secondary" (click)="closePO()">Close PO</p-button>
            <p-button severity="success" (click)="closeView()" icon="fa fa-times"></p-button>
        </div>
    </div>
    <div class="po-header-general-area po-header-section">
        <div class="po-number" (click)="headerDisplayed = !headerDisplayed">
            <div><i class="fa" [class]="headerDisplayed ? 'fa-caret-down' : 'fa-caret-right'"></i></div>
            <div style="margin-left: 10px;"><span *ngIf="!deviceManager.isMobile">PO #: </span>{{poManager.current.number}}</div>
        </div>
        <div class="gap"></div>
        <div class="po-date">
            <span *ngIf="!deviceManager.isMobile">Purchase Date: </span>
            <p-calendar [styleClass]="'calendar-style ' + ThemeManager.theme" inputStyleClass="calendar-input-style"
                        [(ngModel)]="poManager.current.purchaseDate" inputId="icon" dateFormat="yy-mm-dd" (ngModelChange)="setPurchaseDate()" [maxDate]="maxPurchaseDate"
                        [showIcon]="poManager.current.isLocked && authManager.currentUser.unlockPurchaseOrder" [disabled]="!poManager.current.isLocked || !authManager.currentUser.unlockPurchaseOrder"></p-calendar>
        </div>
        <div class="gap"></div>
        <div class="po-total"><span *ngIf="!deviceManager.isMobile">Total: </span>{{ poManager.current.totalPrice | currency:poManager.current.currency }}</div>
    </div>
    <div *ngIf="headerDisplayed" class="po-header-vendor-location-area">
        <div class="po-vendor-area po-header-section">
            <div class="first-vendor-line">
                <div>Vendor:</div>
                <p-dropdown *ngIf="!poManager.current.isLocked && authManager.currentUser.createPurchaseOrder"
                            [options]="vendors" [(ngModel)]="selectedVendorId" optionLabel="name" optionValue="id" [filter]="true" filterBy="name"
                            scrollHeight="400px" placeholder="Change Vendor" (onShow)="loadVendors()" (onChange)="changeVendor($event)"></p-dropdown>
            </div>
            <div>{{poManager.current.vendorName}}</div>
            <div>{{poManager.current.vendor.address}}</div>
            <div>{{poManager.current.vendor.city}} {{poManager.current.vendor.state}} {{poManager.current.vendor.zip}}</div>
        </div>
        <div *ngIf="poManager.current.location != null" class="po-location-area po-header-section">
            <div>Ship To:</div>
            <div>{{authManager.currentUser.purchaseOrderLocationName || poManager.current.location.name}}</div>
            <div>{{authManager.currentUser.purchaseOrderAddress || poManager.current.location.address}}</div>
            <div>{{authManager.currentUser.purchaseOrderCity || poManager.current.location.city}}
                {{authManager.currentUser.purchaseOrderState || poManager.current.location.state}}
                {{authManager.currentUser.purchaseOrderZip || poManager.current.location.zip}}</div>
        </div>
    </div>
    <div class="po-header-image-area">
        <p-fileUpload #fileUpload mode="basic" chooseIcon="pi pi-upload" [disabled]="poManager.current.isLocked"
                      [showUploadButton]="false" [showCancelButton]="false" [customUpload]="true" (onSelect)="uploadImageSelected($event)">
            <ng-template pTemplate="toolbar"></ng-template>
            <ng-template let-file pTemplate="file"></ng-template>
            <ng-template pTemplate="content"></ng-template>
        </p-fileUpload>
        <div *ngFor="let url of poManager.current.imageUrls; let index = index" class="icon-and-trash">
            <i class="fas fa-2x fa-file-image" (click)="onImageClicked(index)" (mouseover)="imageHoverIndex = index" (mouseleave)="imageHoverIndex = null"></i>
            <i *ngIf="!poManager.current.isLocked" class="fa fa-trash" (click)="onImageRemoved(index)" ></i>
        </div>
    </div>

    <div *ngIf="!deviceManager.isMobile && imageHoverIndex != null && ImageApiService.isImageUrl(poManager.current.imageUrls[imageHoverIndex])"
         class="po-image" [style.margin-left.px]="250 + (imageHoverIndex * 35)">
        <img [src]="poManager.current.imageUrls[imageHoverIndex]"/>
    </div>
</div>

<div *ngIf="!gettingSignature" class="po-body">
    <!-- Converters -->
    <div *ngIf="poManager.current.totalNumConverters > 0" class="po-table-section" [style.minHeight.px]="convertersDisplayed ? minTableHeight(poManager.current.lineItemsConverter) : 30">
        <div class="po-table-title" (click)="convertersDisplayed = !convertersDisplayed">
            <div><i class="fa" [class]="convertersDisplayed ? 'fa-caret-down' : 'fa-caret-right'"></i></div>
            <div>Converters</div>
        </div>
        <div *ngIf="deviceManager.isMobile && convertersDisplayed" class="mobile-list-container">
            <div class="mobile-table">
                <div *ngFor="let lineItem of poManager.current.lineItemsAllConverters" class="card" [ngSwitch]="lineItem.type">

                    <!-- Converter -->
                    <ng-container *ngSwitchCase="PurchaseOrderTransactionPartType.Converter">
                        <div class="mobile-row"><div>#: {{lineItem.converterPartNumberReadable}}</div><div>Cat: {{lineItem.converterGroupName}}</div></div>
                        <div *ngIf="editedItemCopy == null || lineItem.id !== editedItemCopy.id" class="mobile-row">
                            <div>Metal: {{lineItem.levelPercentageText}}</div><div>Quantity: {{lineItem.quantity}}</div>
                        </div>
                        <div *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="mobile-row">
                            <div><ng-container *ngTemplateOutlet="levelInput; context: { lineItem: lineItem }"></ng-container></div>
                            <div><ng-container *ngTemplateOutlet="quantityInput; context: { lineItem: lineItem }"></ng-container></div>
                        </div>
                    </ng-container>

                    <!-- Custom -->
                    <ng-container *ngSwitchCase="PurchaseOrderTransactionPartType.Custom">
                        <div class="mobile-row"><div>&lt;Custom&gt;</div><div>Cat: {{lineItem.converterGroupName}}</div></div>
                        <div *ngIf="editedItemCopy == null || lineItem.id !== editedItemCopy.id" class="mobile-row">
                            <div>Quantity: {{lineItem.quantity}}</div>
                        </div>
                        <div *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="mobile-row">
                            <div><ng-container *ngTemplateOutlet="quantityInput; context: { lineItem: lineItem }"></ng-container></div>
                        </div>
                    </ng-container>

                    <!-- No-Number -->
                    <ng-container *ngSwitchCase="PurchaseOrderTransactionPartType.NoNumber">
                        <div class="mobile-row"><div>#: {{lineItem.converterPartNumberReadable}}</div><div>Cat: {{lineItem.converterGroupName}}</div></div>
                        <div *ngIf="editedItemCopy == null || lineItem.id !== editedItemCopy.id" class="mobile-row">
                            <div>Metal: {{lineItem.levelPercentageText}}</div><div>Quantity: {{lineItem.quantity}}</div>
                        </div>
                        <div *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="mobile-row">
                            <div><ng-container *ngTemplateOutlet="levelInput; context: { lineItem: lineItem }"></ng-container></div>
                            <div><ng-container *ngTemplateOutlet="quantityInput; context: { lineItem: lineItem }"></ng-container></div>
                        </div>
                    </ng-container>

                    <!-- Categories -->
                    <ng-container *ngSwitchCase="PurchaseOrderTransactionPartType.Category">
                        <div class="mobile-row"><div>#: {{lineItem.converterPartNumberReadable}}</div><div>Cat: {{lineItem.converterGroupName}}</div></div>
                        <div *ngIf="editedItemCopy == null || lineItem.id !== editedItemCopy.id" class="mobile-row">
                            <div>Quantity: {{lineItem.quantity}}</div>
                            <div *ngIf="!lineItem.categoryHasCustomPrices">Metal: {{lineItem.levelPercentageText}}</div>
                        </div>
                        <div *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="mobile-row">
                            <ng-container *ngTemplateOutlet="quantityInput; context: { lineItem: lineItem }"></ng-container>
                            <div *ngIf="!lineItem.categoryHasCustomPrices">Metal: {{lineItem.levelPercentageText}}</div>
                        </div>
                    </ng-container>

                    <!-- All converter types -->
                    <div class="mobile-row"><div>Price/item: {{lineItem.pricePerItem | currency:poManager.current.currency}}</div>
                        <div style="font-weight: bold">TOTAL: {{lineItem.totalPrice | currency:poManager.current.currency}}</div>
                    </div>

                    <div *ngIf="!poManager.uploadingTransactions" class="mobile-buttons">
                        <span *ngIf="editedItemCopy == null && lineItem.id > 0" class="p-button p-button-text" (click)="onRowEditInit(lineItem)"><i class="pi pi-pencil"></i></span>
                        <span *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="p-button p-button-text p-button-success" (click)="onRowEditSave(poManager.current.lineItemsAllConverters, lineItem)"><i class="pi pi-check"></i></span>
                        <span *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="p-button p-button-text p-button-danger" (click)="onRowEditCancel(poManager.current.lineItemsAllConverters)"><i class="pi pi-times"></i></span>
                        <span *ngIf="editedItemCopy == null && lineItem.id > 0" class="p-button p-button-text" (click)="onDeleteLineItem(poManager.current.lineItemsAllConverters, lineItem)"><i class="fas fa-trash"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!deviceManager.isMobile && convertersDisplayed" class="po-table-container">
            <p-table [value]="poManager.current.lineItemsAllConverters" styleClass="list-table p-datatable-sm" [columns]="converterColumns"
                     [scrollable]="true" scrollHeight="flex" sortField="lastUpdated" [sortOrder]="-1" editMode="row" dataKey="id">
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of converterColumns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="col.sortDisabled || false" [style.width]="col.width">
                            <div [style]="col.style">{{col.header}} <p-sortIcon *ngIf="!(col.sortDisabled || false)" [field]="col.field"></p-sortIcon>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-lineItem let-editing="editing" let-index="rowIndex">
                    <tr [pEditableRow]="lineItem" [class]="index % 2 === 0 ? 'even' : 'odd'">
                        <td *ngFor="let col of converterColumns" [ngSwitch]="col.field" [style.width]="isMobile ? '100%' : col.width" [style]="col.style">
                            <div *ngSwitchCase="'converterPartNumber'">
                                <div [ngSwitch]="lineItem.type">
                                    <div *ngSwitchCase="PurchaseOrderTransactionPartType.Converter">{{lineItem.converterPartNumberReadable}}</div>
                                    <div *ngSwitchCase="PurchaseOrderTransactionPartType.Custom">&lt;Custom&gt;</div>
                                    <div *ngSwitchCase="PurchaseOrderTransactionPartType.NoNumber">{{lineItem.converterPartNumberReadable}}</div>
                                    <div *ngSwitchCase="PurchaseOrderTransactionPartType.Category">{{lineItem.converterPartNumberReadable}}</div>
                                </div>
                            </div>
                            <p-cellEditor *ngSwitchCase="'quantity'">
                                <ng-template pTemplate="input"><ng-container *ngTemplateOutlet="quantityInput; context: { lineItem: lineItem }"></ng-container></ng-template>
                                <ng-template pTemplate="output">{{lineItem.quantity}}</ng-template>
                            </p-cellEditor>
                            <ng-container *ngSwitchCase="'levelPercentageText'">
                                <ng-container *ngIf="lineItem.type === PurchaseOrderTransactionPartType.Converter || lineItem.type === PurchaseOrderTransactionPartType.NoNumber">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input"><ng-container *ngTemplateOutlet="levelInput; context: { lineItem: lineItem }"></ng-container></ng-template>
                                        <ng-template pTemplate="output">{{lineItem.levelPercentageText}}</ng-template>
                                    </p-cellEditor>
                                </ng-container>
                                <ng-container *ngIf="lineItem.type === PurchaseOrderTransactionPartType.Category">
                                    <p-cellEditor *ngIf="!lineItem.categoryHasCustomPrices && !lineItem.byPound">
                                        <ng-template pTemplate="input"><ng-container *ngTemplateOutlet="levelInput; context: { lineItem: lineItem }"></ng-container></ng-template>
                                        <ng-template pTemplate="output">{{lineItem.levelPercentageText}}</ng-template>
                                    </p-cellEditor>
                                    <p-cellEditor *ngIf="lineItem.byPound">
                                        <ng-template pTemplate="input"><ng-container *ngTemplateOutlet="poundsInput; context: { lineItem: lineItem }"></ng-container></ng-template>
                                        <ng-template pTemplate="output">{{lineItem.levelPercentageText}}</ng-template>
                                    </p-cellEditor>
                                    <div *ngIf="lineItem.categoryHasCustomPrices && !lineItem.byPound"><span style="font-style: italic">&lt;Custom&gt;</span></div>
                                </ng-container>
                            </ng-container>
                            <div *ngSwitchCase="'pricePerItem'">{{lineItem.pricePerItem | currency:poManager.current.currency }}</div>
                            <div *ngSwitchCase="'totalPrice'">{{lineItem.totalPrice | currency:poManager.current.currency }}</div>
                            <div *ngSwitchCase="'editColumn'" [hidden]="poManager.uploadingTransactions || poManager.current.isLocked || (editedItemCopy != null && editedItemCopy.id !== lineItem.id) || !authManager.currentUser.createPurchaseOrder" class="desktop-buttons">
                                <p-button *ngIf="!editing && lineItem.id > 0"  pInitEditableRow icon="pi pi-pencil" [text]="true" (click)="onRowEditInit(lineItem)"></p-button>
                                <p-button *ngIf="editing"  pSaveEditableRow icon="pi pi-check" class="p-button-success p-button-text" style="margin-right: .5em" (click)="onRowEditSave(poManager.current.lineItemsAllConverters, lineItem)"></p-button>
                                <p-button *ngIf="editing"  pCancelEditableRow icon="pi pi-times" class="p-button-danger p-button-text" (click)="onRowEditCancel(poManager.current.lineItemsAllConverters)"></p-button>
                                <p-button *ngIf="!editing && lineItem.id > 0"  icon="fas fa-trash" [text]="true" (click)="onDeleteLineItem(poManager.current.lineItemsAllConverters, lineItem)"></p-button>
                            </div>
                            <div *ngSwitchDefault>{{lineItem[col.field]}}</div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <!-- Non-Ferrous Parts -->
    <div *ngIf="poManager.current.lineItemsMiscellaneous.length > 0" class="po-table-section" [style.minHeight.px]="miscDisplayed ? minTableHeight(poManager.current.lineItemsMiscellaneous) : 30">
        <div class="po-table-title" (click)="miscDisplayed = !miscDisplayed">
            <div><i class="fa" [class]="miscDisplayed ? 'fa-caret-down' : 'fa-caret-right'"></i></div>
            <div>Non-Ferrous</div>
        </div>
        <div *ngIf="deviceManager.isMobile && miscDisplayed" class="mobile-list-container">
            <div class="mobile-table">
                <div *ngFor="let lineItem of poManager.current.lineItemsMiscellaneous" class="card">
                    <div class="mobile-row">
                        <div><i class="fa" [class]="lineItem.pricingType === PricingType.ByPiece ? 'fa-puzzle-piece' : 'fa-weight-hanging'"></i></div>
                        <div>{{lineItem.description}}</div>
                    </div>
                    <div *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="mobile-row">
                        <div><ng-container *ngTemplateOutlet="quantityInput; context: { lineItem: lineItem }"></ng-container></div>
                    </div>
                    <div *ngIf="editedItemCopy == null || lineItem.id !== editedItemCopy.id" class="mobile-row">
                        <div>Quantity: {{lineItem.quantity}}</div>
                    </div>
                    <div class="mobile-row"><div>Price/item: {{lineItem.pricePerItem | currency:poManager.current.currency}}</div>
                        <div style="font-weight: bold">TOTAL: {{lineItem.totalPrice | currency:poManager.current.currency}}</div>
                    </div>
                    <div *ngIf="!poManager.uploadingTransactions" class="mobile-buttons">
                        <span *ngIf="editedItemCopy == null && lineItem.id > 0" class="p-button p-button-text" (click)="onRowEditInit(lineItem)"><i class="pi pi-pencil"></i></span>
                        <span *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="p-button p-button-text p-button-success" (click)="onRowEditSave(poManager.current.lineItemsMiscellaneous, lineItem)"><i class="pi pi-check"></i></span>
                        <span *ngIf="editedItemCopy != null && lineItem.id === editedItemCopy.id" class="p-button p-button-text p-button-danger" (click)="onRowEditCancel(poManager.current.lineItemsMiscellaneous)"><i class="pi pi-times"></i></span>
                        <span *ngIf="editedItemCopy == null && lineItem.id > 0" class="p-button p-button-text" (click)="onDeleteLineItem(poManager.current.lineItemsMiscellaneous, lineItem)"><i class="fas fa-trash"></i></span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!deviceManager.isMobile && miscDisplayed" class="po-table-container">
            <p-table [value]="poManager.current.lineItemsMiscellaneous" styleClass="list-table p-datatable-sm" [columns]="miscColumns"
                     [scrollable]="true" scrollHeight="flex" sortField="lastUpdated" [sortOrder]="-1" editMode="row" dataKey="id">
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngFor="let col of miscColumns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="col.sortDisabled || false" [style.width]="col.width">
                            <div [style]="col.style">{{col.header}} <p-sortIcon *ngIf="!(col.sortDisabled || false)" [field]="col.field"></p-sortIcon>
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-lineItem let-editing="editing" let-index="rowIndex">
                    <tr [pEditableRow]="lineItem" [class]="index % 2 === 0 ? 'even' : 'odd'">
                        <td *ngFor="let col of miscColumns" [ngSwitch]="col.field" [style.width]="isMobile ? '100%' : col.width" [style]="col.style">
                            <div *ngSwitchCase="'pricingType'" style="text-align: left; margin-left: 20px;">
                                <span [ngSwitch]="lineItem.pricingType">
                                    <i *ngSwitchCase="PricingType.ByPiece" class="fa fa-puzzle-piece"></i>
                                    <i *ngSwitchCase="PricingType.ByPound" class="fa fa-weight-hanging"></i>
                                </span>
                            </div>
                            <p-cellEditor *ngSwitchCase="'quantity'">
                                <ng-template pTemplate="input"><ng-container *ngTemplateOutlet="quantityInput; context: { lineItem: lineItem }"></ng-container></ng-template>
                                <ng-template pTemplate="output">{{lineItem.quantity}}</ng-template>
                            </p-cellEditor>
                            <div *ngSwitchCase="'pricePerItem'">{{lineItem.pricePerItem | currency:poManager.current.currency }}</div>
                            <div *ngSwitchCase="'totalPrice'">{{lineItem.totalPrice | currency:poManager.current.currency }}</div>
                            <div *ngSwitchCase="'editColumn'" [hidden]="poManager.uploadingTransactions || poManager.current.isLocked || (editedItemCopy != null && editedItemCopy.id !== lineItem.id) || !authManager.currentUser.createPurchaseOrder" class="desktop-buttons">
                                <p-button *ngIf="!editing && lineItem.id > 0"  pInitEditableRow icon="pi pi-pencil" [text]="true" (click)="onRowEditInit(lineItem)"></p-button>
                                <p-button *ngIf="editing"  pSaveEditableRow icon="pi pi-check" class="p-button-success p-button-text" style="margin-right: .5em" (click)="onRowEditSave(poManager.current.lineItemsMiscellaneous, lineItem)"></p-button>
                                <p-button *ngIf="editing"  pCancelEditableRow icon="pi pi-times" class="p-button-danger p-button-text" (click)="onRowEditCancel(poManager.current.lineItemsMiscellaneous)"></p-button>
                                <p-button *ngIf="!editing && lineItem.id > 0"  icon="fas fa-trash" [text]="true" (click)="onDeleteLineItem(poManager.current.lineItemsMiscellaneous, lineItem)"></p-button>
                            </div>
                            <div *ngSwitchDefault>{{lineItem[col.field]}}</div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<ng-template #quantityInput let-lineItem="lineItem">
    <p-inputNumber mode="decimal" [showButtons]="true" inputId="minmax-buttons" [min]="1" [max]="99999" [(ngModel)]="lineItem.quantity" styleClass="quantity-input"></p-inputNumber>
</ng-template>

<ng-template #levelInput let-lineItem="lineItem">
    <p-dropdown [options]="levelOptions" [(ngModel)]="lineItem.levelPercentage" optionLabel="label" optionValue="value" optionDisabled="inactive" appendTo="body" styleClass="level-percentage-input" [scrollHeight]="'300px'"></p-dropdown>
</ng-template>

<ng-template #poundsInput let-lineItem="lineItem">
    <p-inputNumber mode="decimal" [showButtons]="true" inputId="minmax-buttons" [min]="1" [max]="99999" [(ngModel)]="lineItem.levelPercentage" styleClass="quantity-input"></p-inputNumber>
</ng-template>

<ls-purchase-order-pdf #pdf [hidden]="!gettingSignature" (signatureCompleted)="onSignatureCompleted($event)" (generationCompleted)="onPdfGenerated($event)"></ls-purchase-order-pdf>
<!--<ls-compliance-signature *ngIf="gettingSignature" (completed)="onSignatureCompleted($event)"></ls-compliance-signature>-->

<!--<p-slideMenu #printMenu styleClass="print-popup-menu" [popup]="true" [model]="printMenuItems" [menuWidth]="280" [viewportHeight]="5 * 44 + 50"></p-slideMenu>-->
<!--<p-menu #printMenu styleClass="print-popup-menu" [popup]="true" [model]="printMenuItems" [style]="{'width': '280px' }"></p-menu>-->
<p-tieredMenu #printMenu styleClass="print-popup-menu" [popup]="true" [model]="printMenuItems" [style]="{'width': '280px' }"></p-tieredMenu>

<ls-waiting-indicator *ngIf="busy || error" [error]="error" [@fadeInOut] (okClick)="onErrorDismissed()"></ls-waiting-indicator>
