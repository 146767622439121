import { HttpClient } from "@angular/common/http";
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, inject, Injectable, Input, OnInit, Output, Pipe, PipeTransform, Renderer2, ViewChild, ViewContainerRef } from "@angular/core";
import { resolveValue } from "path-value";
import { MessageService } from "primeng/api";
import { Button } from "primeng/button";
import { Calendar } from "primeng/calendar";
import { Checkbox } from "primeng/checkbox";
import { Dropdown } from "primeng/dropdown";
import { PurchaseOrderManagerService } from "src/app/services/purchase-order/purchase-order-manager.service";
import { Utils } from "src/app/services/utils.service";
import { FormUtils } from "src/app/shared-ui/utils/form-utils";
import { CaptureSignatureComponent } from "../capture-signature/capture-signature.component";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ThemeManager } from "src/app/managers/theme.manager";

@Injectable()
export abstract class CompliancePageBase {
    ThemeManager = ThemeManager;

    @Output() signatureCompleted = new EventEmitter<boolean>();
    protected poManager: PurchaseOrderManagerService = inject(PurchaseOrderManagerService);
    protected http: HttpClient = inject(HttpClient);
    protected renderer: Renderer2 = inject(Renderer2);
    protected vcr: ViewContainerRef = inject(ViewContainerRef);
    protected messageService: MessageService = inject(MessageService);

    protected purchaseOrderPage1: string = ""
    protected purchaseOrderPage2: string = ""
    protected purchaseOrderPage3: string = ""
    protected sellerSignature: CaptureSignatureComponent;
    protected sellerSignatureString: string
    protected placeOfSaleOptions = [
        { label: `Seller's Location`, value: 1 },
        { label: `Buyer's Location`, value: 2 },
        { label: 'Other', value: 3 },
    ];

    public showDetails: boolean = false;

    private _observer: MutationObserver
    private dynamicProperties: Map<string, PropertyChangeEventArgs> = new Map()
    private toRender: any[] = []
    private ngModels = {}
    private propertyChanged: EventEmitter<{name: string, value: any}> = new EventEmitter<{name: string, value: any}>();
    
    private attributesTranslations = {
        'optionlabel': 'optionLabel',
        'appendto': 'appendTo',
        'inputstyleclass': 'inputStyleClass',
        '#sellersignature': '#sellerSignature'
    }
    
    abstract GetLocationName(): string;
    abstract generateSignaturePdf(): Promise<void>

    protected get pagesLoaded(): boolean {
        return this.vcr.element.nativeElement.querySelector(`#p1ready`) != null && this.vcr.element.nativeElement.querySelector(`#p2ready`) != null
    }
    protected clearAllFields(): void {
        Object.keys(this.ngModels).forEach(key => {
            this[key] = undefined
            this.ngModels[key] = undefined
        })
    }
    protected clearSignatures(): void
    {
        this.sellerSignature.clear();
    }
    protected onContentReady(): void {
        this.toRender.forEach(el => {
            let tmp = document.createElement('span');
            tmp.innerHTML = el.value;
            if(el.componentType == CustomNgContainerComponent)
                {
                    this.vcr.element.nativeElement.querySelector(el.id).innerHTML = tmp.children[0].innerHTML
                    return;
                }
            let component: any = this.vcr.createComponent(el.componentType)
            for(let i=0; i<tmp.children[0].attributes.length; i++){
                
                let attr = tmp.children[0].attributes[i]


                switch(attr.name){
                    case '(click)':
                        let attributes = Object.values(tmp.children[0].attributes)
                        if(!attributes.map(x => x.name).includes('[disabled]')){
                            component.instance.onClick.subscribe((event) => this[attr.value.replace('()', '')](event));
                            break;
                        }
                        component.instance.onClick.subscribe((event) => {
                            if(!eval(tmp.children[0].attributes['[disabled]'].value))
                                this[attr.value.replace('()', '')](event)
                            else
                                this.messageService.add({ severity: 'warn', summary: 'Empty fields', detail: `Please fill required fields` });
                        })
                        break;
                    case '(change)':
                    case '(onchange)':
                        let params = attr.value.replace(')', '').split('(')
                        if(params[1] == undefined || params[1] == ''){
                            component.instance.onChange.subscribe((event) => this[params[0]](event));
                        }
                        else
                            component.instance.onChange.subscribe((event) => this[params[0]](event, params[1]));
                        break;
                    case 'options':
                    case '[options]':
                        component.instance.options = resolveValue(this, attr.value)
                        break;
                    case '[(ngmodel)]':
                    case 'ngmodel':
                        if(this.ngModels[attr.value] == undefined)
                            this.ngModels[attr.value] = {};
                        this.ngModels[attr.value][component.instance.label] = undefined;
                        this.renderer.addClass(component.location.nativeElement, attr.value)

                        if(el.componentType == CustomInputElement) {
                            if(this.dynamicProperties.has(attr.value)){
                                let value: PropertyChangeEventArgs = this.dynamicProperties.get(attr.value);
                                value.componentInstance = component.instance
                                this.dynamicProperties.set(attr.value, value)
                                
                            } else {
                                this.dynamicProperties.set(attr.value, {componentInstance: component.instance})
                                Object.defineProperty(this, attr.value, {
                                    get: () => {return this[`_${attr.value}`]},
                                    set: (value: any) => {
                                        this[`_${attr.value}`] = value
                                        this.propertyChanged.emit({name: attr.value, value: value})
                                    }
                                 })
                            }
                            }
                        component.instance.onModelChange = e => {
                            if(el.componentType == Checkbox) {
                                this.ngModels[attr.value][component.instance.label] = e[0];  
                                this[attr.value] = Object.values(this.ngModels[attr.value]).filter(x => x != undefined)
                            } else if(el.componentType == Dropdown) {
                                let optValue = tmp.children[0].attributes['optionvalue']
                                if(optValue != undefined)
                                    this[attr.value] = e[optValue.value]
                                else
                                    this[attr.value] = e;
                            } else {
                                this[attr.value] = e;
                            }
                        }
                        break;
                    case '[disabled]':
                        break
                    default:
                        if(attr.value.includes('.'))
                            break;
                        let attrName = this.attributesTranslations[attr.name] ?? attr.name
                        if(attrName.startsWith("#")) {
                            this[attrName.replace('#', '')] = component.instance
                            break
                        }

                        component.instance[attrName] = attr.value
                        break;
                }
            }
            
            let target = this.vcr.element.nativeElement.querySelector(el.id);
            this.renderer.appendChild(target, component.location.nativeElement);
        })
    }
    protected parseDynamicElements(htmlContent: string): string {
        let htmlParsers = [
            {elementName: 'input', componentType: CustomInputElement, regex: '<input(.*?)>'},
            {elementName: 'p-checkbox', componentType: Checkbox, regex: '<p-checkbox(.*?)>'},
            {elementName: 'p-dropdown', componentType: Dropdown, regex: '<p-dropdown(.*?)>'},
            {elementName: 'p-calendar', componentType: Calendar, regex: '<p-calendar(.*?)>'},
            {elementName: 'ls-capture-signature', componentType: CaptureSignatureComponent, regex: '<ls-capture-signature(.*?)>'},
            {elementName: 'p-button', componentType: CustomButtonComponent, regex: '<p-button(.*?)\/>'},
        ]
        
        let propRegex = new RegExp('{{(.*?)}}')
        while(propRegex.test(htmlContent)){
            let result = propRegex.exec(htmlContent)
            let tmpId = `${result[1]}-${Utils.guid()}`
            if(Object.keys(this.dynamicProperties).includes(result[1])){
                htmlContent = htmlContent.replace(result[0], `<span id="${this.dynamicProperties[result[1]]}"></span>`)    
                continue
            }
            if(!this.dynamicProperties.has[result[1]])
                this.dynamicProperties.set(result[1], {componentId: '#' + tmpId})
            htmlContent = htmlContent.replace(result[0], `<span id="${tmpId}"></span>`)
        
            Object.defineProperty(this, result[1], {
               get: () => {return this[`_${result[1]}`]},
               set: (value: any) => {
                   this[`_${result[1]}`] = value
                   this.propertyChanged.emit({name: result[1], value: value})
               }
            })
        }

        htmlParsers.forEach(parser => {
            let regex = new RegExp(parser.regex)
            while(regex.test(htmlContent)) {
                let value = regex.exec(htmlContent)[0]
                let id =`${parser.elementName}-${Utils.guid()}`;
                this.toRender.push({id: '#' + id, value, componentType: parser.componentType})
                htmlContent = htmlContent.replace(value, `<span id="${id}"></span>`)
                
            }
        })
        return htmlContent
    }
    protected getTemplateFromApi(locationName: string): void {
        this.poManager.getPoFromTemplate(this.poManager.current.id, locationName, this.showDetails).subscribe(result => {
            
            if(result.page1 != undefined){
                let page1 = this.parseDynamicElements(result.page1);
                this.purchaseOrderPage1 = '<input type="hidden" id="p1ready">' + page1
            }
            if(result.page2 != undefined){
                let page2 = this.parseDynamicElements(result.page2);
                this.purchaseOrderPage2 = '<input type="hidden" id="p2ready">' + page2
            }
            if(result.page3 != undefined){
                let page3 = this.parseDynamicElements(result.page3);
                this.purchaseOrderPage2 = '<input type="hidden" id="p2ready">' + page3
            }
        });
    }
    private fixEmulatedEncapsulation(): void {
        if (!this.vcr.element?.nativeElement) {
          return;
        }
        const elementRef: HTMLElement = this.vcr.element.nativeElement;
        const ngHostAttribute = elementRef
            .getAttributeNames()
            .find((attr) => attr.startsWith('_nghost-'));
    
        if (!ngHostAttribute) {
            return;
        }
    
        const ngContentAttribute = ngHostAttribute.replace(
            '_nghost-',
            '_ngcontent-'
        );
    
        elementRef
            .querySelectorAll(`:not([${ngContentAttribute}])`)
            .forEach((elem) => elem.setAttribute(ngContentAttribute, ''));
    }
    constructor() {
        this.propertyChanged.subscribe((e) => {
            let value = e.value;
            if(value instanceof Date)
                value = FormUtils.formatDateForDisplay(value)
            let property = this.dynamicProperties.get(e.name)
            if(property.componentInstance != undefined) {
                (property.componentInstance as CustomInputElement).updateModel(value)
            }
            let element = this.vcr.element.nativeElement.querySelector(property.componentId)
            if(element){
                element.innerHTML = value
            }
        }) 

        let contentRendered: boolean = false;
        this._observer = new MutationObserver((m) => {
            m.forEach((mutation, index) => {
                if(this.pagesLoaded && !contentRendered){
                    contentRendered = true
                    this.onContentReady();
                    this.fixEmulatedEncapsulation()
                    this._observer.disconnect()
                }
            })
        })
        this._observer.observe(this.vcr.element.nativeElement, {childList: true, attributes: true, characterData: true, subtree: true})
        this.getTemplateFromApi(this.GetLocationName())
     }
}

export type PropertyChangeEventArgs = {
    componentId?: string
    componentInstance?: any
}
export type SavePdfModel = {
    showDetails: boolean
    locationName: string
    selectedPaymentTypes: number[]
    sellerFirstName: string
    sellerLastName: string
    sellerMiddleInitial: string
    sellerDriversLicenseNumber: string
    sellerDriversLicenseIssuingState: string
    sellerVehicleLicensePlateNumber: string    
    sellerVehicleLicensePlateIssuingState: string
    sellerCaIdNumber: string
    dateOfSale: Date
    name: string
    signature: string
    placeOfSaleStreetAddress: string
    placeOfSaleCity: string
    placeOfSaleState: string
    placeOfSaleZip: string
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'custom-input-element',
    template: '<input type="text" [disabled]="disabled" pInputText #customInput style="width: 100%"/>'
})
export class CustomInputElement implements AfterViewInit{
    @ViewChild('customInput') input: ElementRef<HTMLInputElement>
    onModelChange: Function
    disabled: boolean = false
    ngAfterViewInit(): void {
        this.input.nativeElement.onchange = (e: Event) => this.onModelChange((e.target as HTMLInputElement).value);
    }
    updateModel(value: any){
        if(this.input)
            this.input.nativeElement.value = value;
    }
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'custom-ng-container',
    template: '<p>ASDASD</p>'//'<span class="custom-ng-container"><div class="street-address"><div>ASDASDAS</div><div>Street Address</div></div></span>'
})
export class CustomNgContainerComponent {
    innerHtml: string
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'custom-button-element',
    template: '<p-button (click)="onClick.emit()" [disabled]="disabled" [severity]="severity" [label]="label" #Button ></p-button>'
})
export class CustomButtonComponent {
    @ViewChild('Button') button: Button
    severity: string
    label: string
    onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>
    @Input() disabled: boolean = false;
}

@Pipe({ name: 'safeHtml', standalone: true})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}