import { getCurrencySymbol } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ 
    name: 'currency', 
    standalone: true,
})
export class CurrencyConvertPipe implements PipeTransform {

    transform(value: any, currencyCode: string, addDecimals: boolean = true, ...args: any[]) {

        if(addDecimals || currencyCode == 'USD') {
            value = Math.round(value * 100) / 100;
            value = parseFloat(value).toFixed(2);
        } else {
            value = Math.trunc(value);
        }
        let currencySymbol = getCurrencySymbol(currencyCode, 'narrow');

        if(['USD', 'GBP'].includes(currencyCode))
            return `${currencySymbol}${value}`;

        return `${value} ${currencySymbol}`;
    }
}