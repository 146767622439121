<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<!--<div *ngIf="poManager.current == null && authManager.currentUser.locationId !== 0 && authManager.currentUser.createPurchaseOrder" class="create-po">-->
<!--    <p-dropdown [options]="vendors" [(ngModel)]="selectedVendorId" optionLabel="name" optionValue="id" appendTo="body" styleClass="po-toolbar-vendor-dropdown" [scrollHeight]="dropdownHeight + 'px'"-->
<!--                [filter]="true" filterBy="name" (onShow)="dropdownShown()" (onChange)="createPO()" placeholder="+ PO">-->
<!--        <ng-template pTemplate="selectedItem">-->
<!--            <span class="vendor-dropdown-button">+ PO</span>-->
<!--        </ng-template>-->
<!--    </p-dropdown>-->
<!--</div>-->

<div *ngIf="poManager.current != null" class="info-area" (click)="showPO()">
    <span class="fa fa-shopping-cart" [class.error]="poManager.pendingTransactionsExist"></span>
    <div class="info-column">
        <div>{{poManager.current.number}}</div>
        <div>{{poManager.current.totalPrice | currency:poManager.current.currency}}</div>
        <div>{{poManager.current.totalNumConverters}} pieces</div>
    </div>
</div>
