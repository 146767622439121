import { PurchaseOrderPaymentType } from '../../../../services/purchase-order/purchase-order.model';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { firstValueFrom } from 'rxjs';
import { ImageApiService } from 'src/app/shared-api/images/image-api.service';
import { CompliancePageBase, SavePdfModel } from '../compliance-page-base';
import { Component } from '@angular/core';

@Component({
    selector: 'ls-california-compliance-page',
    templateUrl: './california-compliance-page.component.html',
    styleUrls: [ './california-compliance-page.component.less' ],
})
export class CaliforniaCompliancePageComponent extends CompliancePageBase
{
    GetLocationName(): string {
        return "California"
    }
    currentPage: number = 1

    
    protected onContentReady(): void {
        super.onContentReady();

        this['placeOfSaleOption'] = 1
        this.onPlaceOfSaleOptionChanged()   
    }
    
    checkboxChanged(event: CheckboxChangeEvent, elementId: number){
        if(elementId != PurchaseOrderPaymentType.Cash)
            return
        this.vcr.element.nativeElement.querySelector('#cashPaymentSelected').style.display = event.checked[0] == undefined ? 'block' : 'none'
    }
    onPlaceOfSaleOptionChanged(): void
    {
        if([1,2].includes(this['placeOfSaleOption'])) {
            this.vcr.element.nativeElement.querySelector("#buyer-seller-container").style.display = 'flex'
            this.vcr.element.nativeElement.querySelector("#other-container").style.display = 'none'
        } else {
            this.vcr.element.nativeElement.querySelector("#buyer-seller-container").style.display = 'none'
            this.vcr.element.nativeElement.querySelector("#other-container").style.display = 'flex'
        }
        if (this['placeOfSaleOption']  === 1)
        {
            this['placeOfSaleStreetAddress'] = this.poManager.current.vendor.address;
            this['placeOfSaleCity'] = this.poManager.current.vendor.city;
            this['placeOfSaleState'] = this.poManager.current.vendor.state;
            this['placeOfSaleZip'] = this.poManager.current.vendor.zip;
            return;
        }
        if (this['placeOfSaleOption'] === 2)
        {
            this['placeOfSaleStreetAddress'] = this.poManager.current.location.address;
            this['placeOfSaleCity'] = this.poManager.current.location.city;
            this['placeOfSaleState'] = this.poManager.current.location.state;
            this['placeOfSaleZip'] = this.poManager.current.location.zip;
            return;
        }

        this['placeOfSaleStreetAddress'] = '';
        this['placeOfSaleCity'] = '';
        this['placeOfSaleState'] = '';
        this['placeOfSaleZip'] = '';
    }
    onSelectedContactChanged(): void
    {
        if (!this['sellerContact'].isContactValidForPO) {
            this.messageService.add({ severity: 'error', summary: 'Invalid Contact', detail: `Contact has invalid driver's license information and cannot be selected.` });
            setTimeout(() => {
                this['sellerContact'] = undefined;
                this['sellerLastName'] = '';
                this['sellerFirstName'] = '';
                this['sellerDriversLicenseNumber'] = '';
                this['sellerDriversLicenseIssuingState'] = '';
                if (this.currentPage === 1)
                {
                    this['name'] = '';
                    this['idNum'] = '';
                    this['idState'] = '';
                }
            }, 100);
            return;
        }
        if (this.currentPage === 1)
        {
            this['name'] = this['sellerContact'].fullName;
            this['idNum'] = this['sellerContact'].driversLicenseNumber ?? '';
            this['idState'] = this['sellerContact'].driversLicenseState ?? '';
        }
        else
        {
            this['sellerLastName'] = this['sellerContact'].lastName ?? '';
            this['sellerFirstName'] = this['sellerContact'].firstName ?? '';
            this['sellerDriversLicenseNumber'] = this['sellerContact'].driversLicenseNumber ?? '';
            this['sellerDriversLicenseIssuingState'] = this['sellerContact'].driversLicenseState ?? '';
        }
    }
    onCancel(): void
    {
        this.clearFields();
        this.signatureCompleted.emit(false);
    }
    onConfirmGeneralPage(): void
    {
        this.poManager.current.paymentType =
            (this['selectedPaymentTypes'].includes('1') ? PurchaseOrderPaymentType.Cash : 0) |
            (this['selectedPaymentTypes'].includes('2') ? PurchaseOrderPaymentType.Check : 0) |
            (this['selectedPaymentTypes'].includes('4') ? PurchaseOrderPaymentType.WireTransfer : 0);

        if (this['selectedPaymentTypes'].some(pt => pt === '1'))
        {
            this.currentPage = 2;
            this['sellerContact'] = undefined;
            this.onPlaceOfSaleOptionChanged();
            return;
        }

        this.signatureCompleted.emit(true);
    }
    onConfirmRecordOfSalePage(): void
    {
        this.sellerSignatureString = this.sellerSignature.signature;
        this.currentPage = 1;
        this['idNum'] = this['sellerDriversLicenseNumber'].length > 0 ? this['sellerDriversLicenseNumber'] : this['sellerCaIdNumber'];
        this['idState'] = this['sellerDriversLicenseIssuingState'];
        this['vehicleLicenseNum'] = this['sellerVehicleLicensePlateNumber'];
        this['vehicleLicenseState'] = this['sellerVehicleLicensePlateIssuingState'];
        this.signatureCompleted.emit(true);
    }

    async generateSignaturePdf(): Promise<void> {
        let body: SavePdfModel = {
            locationName: 'California',
            showDetails: this.showDetails,
            dateOfSale: this['dateOfSale'],
            name: this['name'],
            selectedPaymentTypes: this['selectedPaymentTypes'],
            sellerCaIdNumber: this['sellerCaIdNumber'],
            sellerDriversLicenseIssuingState: this['sellerDriversLicenseIssuingState'],
            sellerDriversLicenseNumber: this['sellerDriversLicenseNumber'],
            sellerVehicleLicensePlateIssuingState: this['sellerVehicleLicensePlateIssuingState'],
            sellerVehicleLicensePlateNumber: this['sellerVehicleLicensePlateNumber'],
            sellerMiddleInitial: this['sellerMiddleInitial'],
            sellerFirstName: this['sellerFirstName'],
            sellerLastName: this['sellerLastName'],
            placeOfSaleStreetAddress: this['placeOfSaleStreetAddress'],
            placeOfSaleCity: this['placeOfSaleCity'],
            placeOfSaleState: this['placeOfSaleState'],
            placeOfSaleZip: this['placeOfSaleZip'],
            signature: this.sellerSignatureString
        }

        let url = `https://localhost:5001/api/purchaseOrders/template/generate/${this.poManager.current.id}`

        let result: any = await firstValueFrom(this.http.post(url, body))
        const blob = ImageApiService.b64toBlob(result.pdf, 'application/pdf');
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, '_blank');
    }
    
    private clearFields(): void {
        this.currentPage = 1;
        super.clearAllFields();
    }
}