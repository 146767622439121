import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'ls-capture-signature',
    templateUrl: './capture-signature.component.html',
    styleUrls: [ './capture-signature.component.less' ]
})
export class CaptureSignatureComponent implements OnInit, AfterViewInit
{
    @HostBinding('class') classes = 'capture-signature';

    // @ViewChild('signatureContainer', { static: false }) signatureContainer: ElementRef;
    @ViewChild('signaturePad', { static: false }) signaturePad: SignaturePadComponent;
    onModelChange: Function
    options = {
        minWidth: 1,
        maxWidth: 1,
        canvasWidth: 0,
        canvasHeight: 0,
        penColor: 'black'
    };

    private _widthToHeightRatio: number;

    constructor(private elementRef: ElementRef) { }

    drawComplete(){
        if(this.onModelChange)
            this.onModelChange(this.signaturePad.toDataURL())
    }
    ngOnInit(): void
    {  
    }

    ngAfterViewInit(): void
    {
        this.setupCanvasSize();
    }

    get isEmpty(): boolean { return this.signaturePad == null || this.signaturePad.isEmpty(); }

    get widthToHeightRatio(): number { return this._widthToHeightRatio; }

    clear(): void
    {
        if(this.onModelChange)
            this.onModelChange('')
        this.signaturePad.clear();
    }

    get signature(): any
    {
        return this.signaturePad.toDataURL();
    }

    private setupCanvasSize(): void
    {
        // Because of various ngIf behavior, these elements might not be created/sized yet
        if (this.elementRef.nativeElement == null || this.elementRef.nativeElement.offsetWidth === 0)
        {
            setTimeout(() => this.ngAfterViewInit(), 300);
            return;
        }
        this.signaturePad.set('canvasWidth', this.elementRef.nativeElement.offsetWidth);
        this.signaturePad.set('canvasHeight', this.elementRef.nativeElement.offsetHeight);
        this._widthToHeightRatio = this.elementRef.nativeElement.offsetWidth / this.elementRef.nativeElement.offsetHeight;
    }
}
