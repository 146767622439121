import * as moment from 'moment';

export abstract class Utils
{
    static group(array: Array<any>, key: any): any[]
    {
        const map = {};
        array.map(e => ({ k: key(e), d: e })).forEach(e =>
        {
            map[e.k] = map[e.k] || [];
            map[e.k].push(e.d);
        });
        return Object.keys(map).map(k => ({ key: k, data: map[k] })).sort((a, b) => a.key.localeCompare(b.key));
    }

    static sum(array: any[], propertyName: string): number
    {
        return array.reduce((a, b) => a + b[propertyName], 0);
    }

    // static partsPageSidebarAndContentScrollingEnableDisable(modalVisible)
    // {
    //     const partsListContainer = document.getElementById('legend-parts-list-container');
    //     const partsSidebar = document.getElementById('layout-sidebar');
    //
    //     if (partsListContainer === null || partsSidebar === null) return;
    //
    //     // Enabling - Disabling the scrolling of the background
    //     if (modalVisible)
    //     {
    //         // Disable the scrolling of the background when the "Details" modal popup is opened
    //         partsListContainer.style.height = '100%';
    //         partsListContainer.style.overflow = 'hidden';
    //         partsSidebar.style.height = '100%';
    //         partsSidebar.style.overflow = 'hidden';
    //     }
    //     else
    //     {
    //         // Enable the scrolling when the "Details" modal popup is closed
    //         partsListContainer.style.height = 'auto';
    //         partsListContainer.style.overflow = 'auto';
    //         partsSidebar.style.height = 'auto';
    //         partsSidebar.style.overflow = 'auto';
    //     }
    // }

    // static filterPartWeight(partWeight: number): number
    // {
    //     return parseFloat(partWeight.toFixed(4));
    // }
    //
    // static filterPartPercentage(partPercentage: number): number
    // {
    //     return parseFloat(partPercentage.toFixed(4));
    // }
    //
    // static numericOnly(event: any) {
    //     const pattern = /[0-9]/;
    //
    //     const inputChar = String.fromCharCode(event.charCode);
    //     if (event.keyCode !== 8 && !pattern.test(inputChar)) {
    //         event.preventDefault();
    //     }
    // }
    //
    static convertApiDateStringToDate(dateString: string): Date
    {
        if (dateString == null) { return null; }
        try
        {
            return new Date(dateString.replace(/-/g, '/').replace('T', ' ').replace(/\..*|\+.*/, ''));
        } catch { }
        return null;
    }

    static convertApiUtcDateStringToDate(dateString: string): Date
    {
        if (dateString == null) { return null; }
        try
        {
            return moment.utc(dateString).toDate();
        } catch { }
        return null;
    }

    static getDateApiString(date: Date): string { return moment(date).format('YYYY-MM-DD'); }

    public static groupBy(list: any, keyGetter: any): Map<any, any>
    {
        const map = new Map();
        list.forEach((item: any) =>
        {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection)
            {
                map.set(key, [item]);
            }
            else
            {
                collection.push(item);
            }
        });
        return map;
    }

    //
    // static convertStringToDate(dateString: string): Date {
    //
    //     // If timezone is not specified in the string, parse it as "local" time.
    //     if (dateString.indexOf('Z') === -1 && dateString.indexOf('+') === -1) {
    //         const b = dateString.split(/\D/);
    //         return new Date(+b[ 0 ], +b[ 1 ] - 1, +b[ 2 ], +b[ 3 ], +b[ 4 ], +b[ 5 ]);
    //     }
    //
    //     return new Date(dateString);
    // }

    // static get currentDateUTC(): Date
    // {
    //     const date = new Date();
    //     const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(),
    //     date.getUTCMinutes(), date.getUTCSeconds());
    //     return new Date(now_utc);
    // }

    // static replaceAll(originalString: string, search: string, replacement: string) {
    //     return originalString.split(search).join(replacement);
    // }

    static guid() : string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
}
