import * as Fingerprint from '@fingerprintjs/fingerprintjs';
import { Observable, of } from 'rxjs';
import { AuthTokenResponse } from '../models/auth-token-response';
import { Injectable } from '@angular/core';
import { share } from 'rxjs/operators';
import { PurchaseOrder } from '../services/purchase-order/purchase-order.model';
import { PurchaseOrderTransaction } from '../services/purchase-order/purchase-order-transaction';

@Injectable({
    providedIn: 'root'
})
export class LocalCacheManager
{
    private static _uniqueId: string;

    private static keys = {
        TokensAndExpirations: 'TokensAndExpirations',
        // UserId: 'UserId',
        RememberMe: 'RememberMe',
        // EmailAddress: 'EmailAddress',
        // CurrentRoute: 'CurrentRoute',
        LastCRMTab: 'LastCRMTab',
        PurchaseOrder: 'PurchaseOrder',
        TransactionQueue: 'TransactionQueue',
        TransactionQueueItemId: 'TransactionQueueItemId',
        PartNumberSettings: 'PartNumberSettings',
        ShowPriceGraphs: 'ShowPriceGraphs',
        Currency: 'Currency',
        AvailableCurrencies: 'AvailableCurrencies',
        MapZoomLevel: 'MapZoomLevel'
    };

    constructor()
    {
        // const nextID = Math.min.apply(null, (this.transactionQueue ?? []).map(item => item.transactionId));
    }

    static get tokensAndExpirations(): AuthTokenResponse
    {
        return JSON.parse(localStorage[this.keys.TokensAndExpirations]);
    }

    static set tokensAndExpirations(tokensAndExpirations: AuthTokenResponse)
    {
        localStorage[this.keys.TokensAndExpirations] = JSON.stringify(tokensAndExpirations);
    }

    // Access Token
    static get accessToken(): string
    {
        const cachedToken = localStorage[this.keys.TokensAndExpirations] ? JSON.parse(localStorage[this.keys.TokensAndExpirations]).token : '';
        const cachedExpiration = localStorage[this.keys.TokensAndExpirations] ?
            new Date(JSON.parse(localStorage[this.keys.TokensAndExpirations]).expiration) : '';
        if (cachedToken !== '' && cachedExpiration !== '' && cachedExpiration > new Date())
        {
            return cachedToken;
        }
        return '';
    }

    // Refresh Token
    static get refreshToken(): string
    {
        const cachedRefreshToken = localStorage[this.keys.TokensAndExpirations] ?
            JSON.parse(localStorage[this.keys.TokensAndExpirations]).refreshToken : '';
        if (cachedRefreshToken !== '')
        {
            return cachedRefreshToken;
        }
        return '';
    }

    static get uniqueId(): Observable<string>
    {
        if (this._uniqueId != null)
            return of(this._uniqueId);

        return new Observable<string>(observer => {
            const self = this;
            Fingerprint.load().then(fp => {
                fp.get().then(result => {
                    self._uniqueId = result.visitorId;
                    observer.next(self._uniqueId);
                    observer.complete();
                });
            });
        }).pipe(share());
    }

    // get isRefreshTokenExpired(): boolean
    // {
    //  const cachedRefreshToken = localStorage[this.keys.TokensAndExpirations] ?
    //  JSON.parse(localStorage[this.keys.TokensAndExpirations])['refreshToken'] : '';
    //     const cachedRefreshExpiration = localStorage[this.keys.TokensAndExpirations] ?
    //         new Date(JSON.parse(localStorage[this.keys.TokensAndExpirations])['refreshTokenExpiration']) : '';
    //     return cachedRefreshToken !== '' && cachedRefreshExpiration !== '' && cachedRefreshExpiration > new Date();
    // }

    // RememberMe Flag
    static get rememberMe(): boolean
    {
        return localStorage[this.keys.RememberMe] === 'true';
    }

    static set rememberMe(value: boolean)
    {
        localStorage[this.keys.RememberMe] = value;
    }

    static signOut(): void
    {
        this._uniqueId = null;
        localStorage.removeItem(this.keys.TokensAndExpirations);
        localStorage.removeItem(this.keys.PurchaseOrder);
        localStorage.removeItem(this.keys.TransactionQueue);
    }

    static get currentPO(): PurchaseOrder
    {
        if (!localStorage[this.keys.PurchaseOrder]) { return null; }
        return new PurchaseOrder(JSON.parse(localStorage[this.keys.PurchaseOrder]));
    }

    static set currentPO(po: PurchaseOrder)
    {
        if (po == null)
        {
            localStorage.removeItem(this.keys.PurchaseOrder);
            return;
        }

        localStorage[this.keys.PurchaseOrder] = JSON.stringify(po);
    }

    static get transactionQueue(): PurchaseOrderTransaction[]
    {
        if (!localStorage[this.keys.TransactionQueue]) { return []; }
        return JSON.parse(localStorage[this.keys.TransactionQueue]).map(item => new PurchaseOrderTransaction(item));
    }

    static set transactionQueue(queue: PurchaseOrderTransaction[])
    {
        localStorage[this.keys.TransactionQueue] = JSON.stringify(queue);
        if (queue.length === 0) localStorage[this.keys.TransactionQueueItemId] = 0;
    }

    static get nextTransactionQueueItemId(): number
    {
        const nextId = (localStorage[this.keys.TransactionQueueItemId] ?? 0) - 1;
        localStorage[this.keys.TransactionQueueItemId] = nextId;
        return nextId;
    }

    static get partNumberSettings(): any
    {
        if (!localStorage[this.keys.PartNumberSettings]) { return {}; }
        return JSON.parse(localStorage[this.keys.PartNumberSettings]);
    }

    static set partNumberSettings(obj: any)
    {
        localStorage[this.keys.PartNumberSettings] = JSON.stringify(obj);
    }

    static get showPriceGraphs(): boolean
    {
        return localStorage[this.keys.ShowPriceGraphs] === 'true';
    }

    static set showPriceGraphs(value: boolean)
    {
        localStorage[this.keys.ShowPriceGraphs] = value;
    }

    static get currency(): string
    {
        return localStorage[this.keys.Currency] ?? 'USD';
    }

    static set currency(value: string)
    {
        localStorage[this.keys.Currency] = value;
    }

    static get availableCurrencies(): string[]
    {
        if (!localStorage[this.keys.AvailableCurrencies]) { return []; }
        return JSON.parse(localStorage[this.keys.AvailableCurrencies]);
    }
    
    static set availableCurrencies(value: string[])
    {
        localStorage[this.keys.AvailableCurrencies] = JSON.stringify(value);
    }

    static get mapZoomLevel(): number
    {
        return localStorage[this.keys.MapZoomLevel] ?? 8;
    }

    static set mapZoomLevel(value: number)
    {
        localStorage[this.keys.MapZoomLevel] = value;
    }

    static get lastCRMTab(): string
    {
        return localStorage[this.keys.LastCRMTab];
    }

    static set lastCRMTab(value: string)
    {
        localStorage[this.keys.LastCRMTab] = value;
    }
}
